// JavaScript source code
import { Grid, Container } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
import { getWorkFlowHistoryData } from "../../Redux/API/api_userManagemnt";
import MaterialTable from "material-table";
import { tableIcons } from "../../Utility/utility";
import { useSelector } from "react-redux";
export default function WorkflowHistory() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);//azure-token
    const UID = useSelector((state) => state.saveUserIdReducer);
    let [isLoader, setIsLoader] = useState(false);
    const [workflowDetails, setWorkflowDetails] = useState([]);
    var columns = [
        {
            title: "Request no.",
            field: "requestNumber",
            
            export: true,
        },
        { title: "Email Id", field: "emailId" },
        { title: "Remarks", field: "remarks" },

        {
            title: "Current Status",
            field: "currentStatus",
        },
       
        {
            title: "Action Taken On",
            field: "actionDate",
            editable: "never",
            render: (rowData) => {
                if (rowData.actionDate != null && rowData.actionDate != undefined) {
                    const dateStr = new Date(rowData.actionDate);
                    const options = {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                       
                        hour12: true,
                    };
                    
                    const retDateStr = dateStr.toLocaleDateString("en-US", options);
                    return retDateStr;
                }
            }
        }
    ];
    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }
    function getURLParameter(sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split("&");
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] == sParam) {
          return decodeURIComponent(sParameterName[1]);
        }
      }
      // If parameter is not found, return null or empty string
      return null; // or return '';
    }
    var encryptedId = getURLParameter("id");
   
    function getWorkFlowHistory(value) {
        setIsLoader(true);
        getWorkFlowHistoryData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            value)
            .then((response) => {
              setWorkflowDetails(response);

              setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    } 
    useEffect(() => {
        if (encryptedId) {
            getWorkFlowHistory(atobSecure(encryptedId));
           
        }
    }, [encryptedId]);

    return (
        <div>
            <div className="main-component mb-1">
                <Container className="box-shadow details-panel" maxWidth="xl">
                    <Grid item xs={6} md={8} className="d-flex jc-flex-end p-0">
                        <a href="/RPAWorkFlow" className="template-text">
                            &lt; &lt; Back To List Screen
                        </a>

                    </Grid> 
                  
                    <Grid item xs={12} className="req-grid pb-1 pt-1">
                        <MaterialTable
                            title="Workflow History Details"
                            columns={columns}
                            data={workflowDetails}
                            icons={tableIcons}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: {
                                    csv: true,
                                    pdf: false,
                                },
                                search: false,
                                paging:false,
                              
                                exportAllData: true,
                                exportFileName: "Workflow History Details",
                                
                                rowStyle: (x) => {
                                    if (x.tableData.id % 2) {
                                        return { backgroundColor: "#F2FCFC" };
                                    } else {
                                        return { backgroundColor: "white" };
                                    }
                                },
                            }}
                        
                        />
                    </Grid>
                </Container>
                {isLoader ? <Loader /> : <></>}
            </div>
            

        </div>
    );
}
