// JavaScript source code
import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip, IconButton, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { baseAddressURL, tableIcons } from "../../Utility/utility";
import { Container } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getRequestsList, getRpaData } from "../../Redux/API/api_userManagemnt";
import "./Workflow.scss";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
    approveRPA,
    rejectRPA,
    approveL1,
    rejectL1,
    getWorkFlowHistoryData,
} from "../../Redux/API/api_userManagemnt";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import { HistoryOutlined } from "@mui/icons-material";
import { saveReqNoAction } from "../../Redux/Actions/actions";

export default function RPAWorkFlow() {
    const dispatch = useDispatch();
    let [isLoader, setIsLoader] = useState(false);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer); //azure-token
    const UID = useSelector((state) => state.saveUserIdReducer);

    const [rpaTableData, setRpaTableData] = useState([]);

    function getRPATableData() {
        setIsLoader(true);
        getRequestsList(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId
        )
            .then((response) => {
                setRpaTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    const [openFormApprove, setOpenFormApprove] = useState(false);
    const [openFormReject, setOpenFormReject] = useState(false);

    const handleCloseFormApprove = () => {
        setOpenFormApprove(false);
    };
    const handleCloseForm = () => {
        setOpenFormReject(false);
    };
    const [requestId, setRequestId] = useState();
    const [requestNo, setRequestNo] = useState("");
    const [status, setStatus] = useState(0);

   

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    function ApproveDocumentButton(rowData) {
      function viewToolCaseData() {
        let id = rowData.requestId;
        dispatch(saveReqNoAction(rowData.requestNumber));
        let idEncrypted = btoaSecure(id);
        window.location.href =
          baseAddressURL + "/CompleteDetails?id=" + idEncrypted;
      }
      function handleClickOpenFormApprove() {
        setOpenFormApprove(true);
        setRequestId(rowData.requestId);
        setRequestNo(rowData.requestNumber);
        setStatus(rowData.statusId);
      }
      function handleClickOpenFormReject() {
        setOpenFormReject(true);
        setRequestId(rowData.requestId);
        setRequestNo(rowData.requestNumber);
        setStatus(rowData.statusId);
      }
      function workflowHistory() {
        let id = rowData.requestId;
        let idEncrypted = btoaSecure(id);
        window.location.href =
          baseAddressURL + "/WorkflowHistory?id=" + idEncrypted;
      }
      return (
        <div>
          {(rowData.statusId === 1 &&
            userDetails.roleId === 11 &&
            rowData.emailId !== userDetails.userEmail) ||
          (rowData.statusId === 1 && userDetails.roleId === 9) ||
          (rowData.statusId === 2 && userDetails.roleId === 12) ||
          (rowData.statusId === 2 && userDetails.roleId === 9) ? (
            <IconButton
              className="activationButton"
              aria-label="Approve"
              onClick={handleClickOpenFormApprove}
              style={{ left: `-12px` }}>
              <Tooltip title="Approve">
                <CheckIcon style={{ color: `green` }} />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
          {(rowData.statusId === 1 &&
            userDetails.roleId === 11 &&
            rowData.emailId !== userDetails.userEmail) ||
          (rowData.statusId === 1 && userDetails.roleId === 9) ||
          (rowData.statusId === 2 && userDetails.roleId === 12) ||
          (rowData.statusId === 2 && userDetails.roleId === 9) ? (
            <IconButton
              className="activationButton"
              aria-label="reject"
              style={{ left: `-12px` }}
              onClick={handleClickOpenFormReject}>
              <Tooltip title="Reject">
                <CloseIcon style={{ color: `red` }} />
              </Tooltip>
            </IconButton>
          ) : (
            <></>
          )}
          <IconButton
            className="activationButton"
            aria-label="View Details"
            onClick={viewToolCaseData}
            style={{ left: `-12px` }}>
            <Tooltip title="View Details">
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            className="activationButton"
            aria-label="reject"
            onClick={workflowHistory}>
            <Tooltip title="View History Details">
              <HistoryOutlined />
            </Tooltip>
          </IconButton>
        </div>
      );
    }

    var columns = [
      {
        title: "Request no.",
        field: "requestNumber",
        render: (rowData) => rowData && viewRequestDetails(rowData),
        export: true,
      },
      { title: "Tool Name", field: "toolName" },
      { title: "Requester GID", field: "gid" },

      {
        title: "Requester E-mail",
        field: "emailId",
      },
      {
        title: "Status",
        field: "statusName",
      },
      {
        title: "Actions",
        render: (rowData) => ApproveDocumentButton(rowData),
      },
    ];

    function btoaSecure(value) {
      const { btoa } = require("abab");
      let currDate = new Date().toDateString();
      let valueModified = value + " " + currDate;
      let valueSecured = btoa(btoa(valueModified));
      return valueSecured;
    }

    //approveRPA
    var approveRPAFormData = {
        RequestId: requestId,
        Reason: "",
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,

    }
    function approveRPAData() {
      setIsLoader(true);
      approveRPA(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
          requestId,
          approveRPAFormData
      )
        .then((response) => {
          if (response.status == 1) {
            handleSnackOpen(response.responseMsg, "success");

            setIsLoader(false);
            getRPATableData();
            setOpenFormApprove(false);
          } else {
            handleSnackOpen("Error While Approving Request.", "error");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    //L1 Approval
//    public int RequestId { get; set; }
//public string Reason { get; set; }
//public int ActionOwnerUserId{ get; set; }
    //public string ActionOwnerEmailId { get; set; }
    var approveL1FormData = {
        RequestId: requestId,
        Reason: "",
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,

    }
    function approveL1Data() {
      setIsLoader(true);
      approveL1(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
          requestId,
          approveL1FormData
      )
        .then((response) => {
          if (response.status == 1) {
            handleSnackOpen(response.responseMsg, "success");
            setIsLoader(false);
            getRPATableData();
            setOpenFormApprove(false);
          } else {
            handleSnackOpen("Error while Approving Request.", "error");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    //rejectRPA
    const [rejectReason, setRejectReason] = useState("");
    const handleRejectReason = (e) => {
      setRejectReason(e.target.value);
    };
    //workFlowHistory

    function getWorkFlowHistory(requestNo) {
      setIsLoader(true);
      getWorkFlowHistoryData(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
        requestNo
      )
        .then((response) => {
          window.location.href = baseAddressURL + "/WorkflowHistory";
          setIsLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    //RPA Reject
    var rejectedRPAFormData = {
        RequestId: requestId,
        Reason: rejectReason,
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,

    }
    function RejectRPAData() {
        setIsLoader(true);
        rejectRPA(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            requestId,
            rejectReason,
            rejectedRPAFormData
        )
            .then((response) => {
                if (response.status == 1) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                    getRPATableData();
                    setOpenFormReject(false);
                    setRejectReason("");
                } else {
                    handleSnackOpen("Error while Rejecting Request.", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //L1 Reject
    var rejectedl1FormData = {
        RequestId: requestId,
        Reason: rejectReason,
        ActionOwnerUserId: userDetails.userId,
        ActionOwnerEmailId: userDetails.userEmail,
        

    }
    function RejectL1Data() {
        setIsLoader(true);
        rejectL1(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            requestId,
            rejectReason,
            rejectedl1FormData
        )
            .then((response) => {
                if (response.status == 1) {
                    handleSnackOpen(response.responseMsg,"success");
                    setIsLoader(false);
                    getRPATableData();
                    setOpenFormReject(false);
                    setRejectReason("");
                } else {
                    handleSnackOpen("Error while Rejecting Request.", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function viewRequestDetails(rowData) {
        let id = rowData.requestId;
        let idEncrypted = btoaSecure(id);
        return (
            <Tooltip title="View Request Details">
                <Link to={"/CompleteDetails?id=" + idEncrypted}>
                    <span className="template-text">{rowData.requestNumber}</span>
                </Link>
            </Tooltip>
        );
    }

    useEffect(() => {
        getRPATableData();
    }, []);

    return (
        <div className="main-component">
            <Container className="" maxWidth="xl">
                <Grid container spacing={1}>
                    <Grid item xs={12} className="d-flex jc-space-bt">
                        <Grid item xs={9} className="text-left ml-1">
                            <h3 className="m-0">Requests List</h3>
                        </Grid>
                        <Grid item xs={3} className="text-right"></Grid>
                    </Grid>
                    <Grid item xs={12} className="req-grid-list">
                        <MaterialTable
                            title=""
                            columns={columns}
                            data={rpaTableData}
                            icons={tableIcons}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: {
                                    csv: true,
                                    pdf: false,
                                },
                                search: true,

                                exportAllData: true,
                                exportFileName: "RequestList",
                                pageSize: 10,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 20, 30, 50],
                                rowStyle: (x) => {
                                    if (x.tableData.id % 2) {
                                        return { backgroundColor: "#F2FCFC" };
                                    } else {
                                        return { backgroundColor: "white" };
                                    }
                                },
                            }}
                      
                        />
                    </Grid>
                    <Grid item xs={12} sm={1} className="mb-1">
                        <Link to={"/"}>
                            <Button
                                type="submit"
                                className="pt-button--tertiary"
                                id="SupplierLoginBtn"
                                fullWidth>
                                &lt; &lt; Back
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                <Dialog
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseFormApprove(event, reason);
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openFormApprove}
                    maxWidth={"md"}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <h5>Do You Want To Approve {requestNo} Request ? </h5>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            className="pt-button--secondary"
                            id="SupplierLoginBtn"
                            onClick={handleCloseFormApprove}>
                            close
                        </Button>
                        {userDetails.roleId === 11 ||
                            (userDetails.roleId === 9 && status === 1) ? (
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={approveL1Data}>
                                Submit
                            </Button>
                        ) : (
                            <></>
                        )}
                        {userDetails.roleId === 12 ||
                            (userDetails.roleId === 9 && status === 2) ? (
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={approveRPAData}>
                                Submit
                            </Button>
                        ) : (
                            <></>
                        )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseForm(event, reason);
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openFormReject}
                    maxWidth={"md"}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container spacing={2}>
                                <h5>Please provide reason to reject {requestNo} request</h5>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Reason"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={rejectReason}
                                        onChange={handleRejectReason}
                                        variant="filled"></TextField>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            className="pt-button--secondary"
                            id="SupplierLoginBtn"
                            onClick={handleCloseForm}>
                            close
                        </Button>
                        {userDetails.roleId === 11 ||
                            (userDetails.roleId === 9 && status === 1) ? (
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                disabled={!rejectReason}
                                onClick={RejectL1Data}>
                                Submit
                            </Button>
                        ) : (
                            <></>
                        )}
                        {userDetails.roleId === 12 ||
                            (userDetails.roleId === 9 && status === 2) ? (
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                disabled={!rejectReason}
                                onClick={RejectRPAData}>
                                Submit
                            </Button>
                        ) : (
                            <></>
                        )}
                    </DialogActions>
                </Dialog>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </Container>
            {isLoader === true ? <Loader /> : <></>}
        </div>
    );
}
