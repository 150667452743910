import { Routes, Route } from "react-router-dom";
import LoginRoot from "../Screens/LoginRegister/LoginRegister";
import Error from "../Screens/Error/Error404";

export const LoginRoute = (
  <Routes>
    <Route path="/" element={<LoginRoot />} />
    <Route path="*" element={<Error />} />
  </Routes>
);
