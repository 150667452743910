import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Grid,
    Button,
    TextField,
    MenuItem,
    Container,
    Divider,
    Autocomplete,
    Tooltip,
} from "@mui/material";


import MaterialTable from "material-table";
import { tableIcons, baseAddressURL } from "../../../Utility/utility";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Loader from "./../../../Components/Loader/Loader";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { useSelector } from "react-redux";
import {
    getDivisionMenus,
    getDepartmentMenus,
    getDyanamicTypeList,
    dyanamicFieldSubmit,
    dyanamicFieldUpdate,
    dyanamicFieldAddValueUpdate,
    getDyanamicTypeListTableData,
    dyanamicFieldAddValue,
    getDyanamicFieldListTableData,
} from "../../../Redux/API/api_userManagemnt";
import { GetToolsList } from "../../../Redux/API/api_raiseRequest";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// JavaScript source code

export default function AddDynamicFields() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer); //azure-token
    const UID = useSelector((state) => state.saveUserIdReducer);

    const [labelDataInput, setLabelDataInput] = useState("");
    const [labelType, setLabelType] = useState(0);
    const [additionalField, setAdditionalField] = useState();
    const [additionalFieldValue, setAdditionalFieldValue] = useState();
    const [additionalData, setAdditionalData] = useState();
    const [labelDataInputName, setLabelDataInputName] = useState();
    const [dynamicFieldValue, setDynamicFieldValue] = useState("");
    const [additionalFieldwhileAdding, setAdditionalFieldwhileAdding] =
        useState("");
    const [toolsValue, setToolsValue] = useState(null);
    const [toolName, setToolName] = useState();
    const [toolsId, setToolsId] = useState("");
    const [tools, setTools] = useState([]);
    const [status, setStatus] = useState(true);
    const [statusSubmit, setStatusSubmit] = useState(true);
    const handleInputChangeDynamicFieldValue = (e) => {
        setDynamicFieldValue(e.target.value);
    };
    const handleInputChangeforAdd = (e) => {
        setAdditionalFieldwhileAdding(e.target.value);
    };
    const [statusTableUpdate, setStatusTableUpdate] = useState();
    const [dynamicFieldValueUpdate, setDynamicFieldValueUpdate] = useState("");
    const handleInputChangeDynamicFieldValuewhileUpdate = (e) => {
        setDynamicFieldValueUpdate(e.target.value);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleCloseModalforAdd = () => {
        setOpenAddModal(false);
    };
    const dialogOpener = () => {
        setOpenModal(true);
    };
    var columns = [
        {
            title: "Dynamic Field",
            field: "dynamicField.dynamicFieldName",
        },
        {
            title: "Field Value",
            field: "dynamicFieldValue",
        },
        { title: "Status", field: "isActive" },
    ];
    var dropdownData = [
        {
            id: 11,
            label: "Option 1 Dropdown",
            value: 11,
        },
        {
            id: 12,
            label: "Option 2 Dropdown",
            value: 12,
        },
        {
            id: 13,
            label: "Option 3 Dropdown",
            value: 13,
        },
    ];

    var multiSelectData = [
        {
            id: 21,
            label: "Option 1 MSDropdown",
            value: 21,
        },
        {
            id: 22,
            label: "Option 2 MSDropdown",
            value: 22,
        },
        {
            id: 23,
            label: "Option 3 MSDropdown",
            value: 23,
        },
    ];
    function getAllTools() {
        setIsLoading(true);
        GetToolsList(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId
        )
            .then((response) => {
                setTools(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            if (sParameterName[0] == sParam) {
                return decodeURIComponent(sParameterName[1]);
            }
        }
        // If parameter is not found, return null or empty string
        return null; // or return '';
    }
    var encryptedId = getURLParameter("id");
    const [allData, setAllData] = useState([]);
    const [allDataMs, setAllDataMs] = useState([]);

    const [selectedOptionsMSelect, setSelectedOptionsMSelect] = useState([]);
    const [filterMSelect, setFilterMSelect] = useState("");
    function editSOfficeByUser(filterMSelect) {
        var arrayMSelect = filterMSelect.split(",");
        setSelectedOptionsMSelect(
            allDataMs.filter((x) => arrayMSelect.includes(x.value))
        );
    }
    let mSelectArr = [];
    const [mSelectString, setMSelectString] = useState(""); //real value to be passed in api
    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }
    function onChangeMSelect(value, event) {
        let mSelectValue = [];
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
            mSelectValue = [];
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
        if (event.action === "select-option" && event.option.value === "*") {
            mSelectValue = allDataMs;
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            mSelectValue = [];
        } else {
            mSelectValue = value;
        }
        for (let i = 0; i < mSelectValue.length; i++) {
            if (mSelectValue[i].value !== undefined)
                mSelectArr.push(mSelectValue[i].value);
        }
        setMSelectString(mSelectArr.toString());
    }
    function clickAddDynamicValue() {
        setOpenAddModal(true);

    }
    const [additionalFieldLabelTypeId, setAdditionalFieldLabelTypeId] =
        useState();
    const [dynamicFieldValueIdUpdate, setDynamicFieldValueIdUpdate] = useState();
    const clickEditHardCodeMaster = (rowData) => {
        setDynamicFieldValueUpdate(rowData.dynamicFieldValue);
        setStatusTableUpdate(rowData.isActive == "Active" ? true : false);
        setDynamicFieldValueIdUpdate(rowData.dynamicFieldValueId);
        setOpenModal(true);
        setAdditionalField(rowData.dynamicField.dynamicFieldName);
        setAdditionalFieldLabelTypeId(
            rowData.dynamicField.dynamicFieldType.dynamicFieldTypeId
        );
        if (rowData.dynamicField.dynamicFieldType.dynamicFieldTypeId == 1) {
            setAllData(dropdownData);

        } else {
            setAllDataMs(multiSelectData);
        }
    };
    function GetEditButton(rowData) {
        return (
            <Tooltip title="Edit Field-value" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleInputChangeLabel = (e) => {
        setLabelDataInput(e.target.value);
    };
    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "LabelType":
                setLabelType(event.target.value);

                break;
            case "labelDataInputName":
                setLabelDataInputName(event.target.value);

                break;
            case "status":
                setStatus(event.target.value);

                break;
            case "statusSubmit":
                setStatusSubmit(event.target.value);
                break;

            case "statusTableUpdate":
                setStatusTableUpdate(event.target.value);
                break;

            default:
                break;
        }
    };

    const handleClose = (e) => {
        setOpen(false);
        if (alertType === "success" && !encryptedId) {
            window.location.href = "/FieldsList";
        }
        if (alertType === "success" && encryptedId) {
            window.location.reload();

        }
    };
    // && !encryptedId
    const [getDynamidTypeList, setGetDynamidTypeList] = useState([]);
    function getDyanamicTypeMenus() {
        getDyanamicTypeList(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId
        )
            .then((response) => {
                setGetDynamidTypeList(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //getData
    const [dynamidFieldIdForm, setDynamidFieldIdForm] = useState();
    function getDyanamicFieldAllData(value) {
        getDyanamicFieldListTableData(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            value
        )
            .then((response) => {
                if (response && value) {
                    let FilteredData = response.filter((x) => x.dynamicFieldId == value);

                    getTableData(FilteredData[0].dynamicFieldId);

                    setDynamidFieldIdForm(FilteredData[0].dynamicFieldId);
                    setLabelDataInput(FilteredData[0].dynamicFieldName);

                    setLabelType(FilteredData[0].dynamicFieldType.dynamicFieldTypeId);
                    setfieldTypeUpdate(FilteredData[0].dynamicFieldType.dynamicFieldTypeId)
                    setStatusSubmit(FilteredData[0].isActive == "Active" ? true : false);

                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    var formdataDyanamicField = {
        DynamicFieldId: dynamidFieldIdForm,
        DynamicFieldName: labelDataInput,
        DynamicFieldTypeId: labelType,
        IsActive: statusSubmit,
        CreatedBy: userDetails.userId,
        CreatedDate: "",
        ModifiedBy: 0,
        ModifiedDate: "",
    };

    function submitDynamicFields() {
        setIsLoading(true);
        dyanamicFieldSubmit(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataDyanamicField
        )
            .then((response) => {
                setIsLoading(false);

                if (response.status == 0) {
                    handleSnackOpen(response.message, "error");
                } else if (response.status == 1) {
                    handleSnackOpen(response.message, "success");
                } else if (response.status == 2) {
                    handleSnackOpen(response.message, "success");
                } else {
                    handleSnackOpen("Error While Submitting Dynamic Field Data", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //update dynamic field
    const [fieldTypeUpdate, setfieldTypeUpdate] = useState();
    function submitDynamicFieldsUpdate() {
        setIsLoading(true);
        dyanamicFieldUpdate(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataDyanamicField
        )
            .then((response) => {
                setIsLoading(false);
               
                if (response.status == 0) {
                    handleSnackOpen(response.message, "error");
                } else if (response.status == 1) {
                    handleSnackOpen(response.message, "success");
                } else if (response.status == 2) {
                    setfieldTypeUpdate(response.objResult.dynamicFieldTypeId);

                    handleSnackOpen(response.message, "success");
                } else {
                    handleSnackOpen("Error While Submitting Dynamic Field Data", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
   
    //handleSubmitDialog
    var formdataDyanamicFieldDialog = {
        DynamicFieldId: 0,
        DynamicFieldName: additionalField, // dynamicFieldName
        DynamicFieldTypeId: additionalFieldLabelTypeId, //labelType
        IsActive: true,
        CreatedBy: userDetails.userId,
        CreatedDate: "",
        ModifiedBy: 0,
        ModifiedDate: "",
    };

    function handleSubmitDialog() {
        setIsLoading(true);
        dyanamicFieldSubmit(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataDyanamicFieldDialog
        )
            .then((response) => {
                setIsLoading(false);

                if (response.isSuccess == true) {
                    handleSnackOpen(response.message, "success");
                } else {
                    handleSnackOpen("Error While Submitting Dynamic Field Data", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //getDyanamicTypeListTableData
    const [dynamicTableData, setDynamicTableData] = useState();
    function getTableData(value) {
        setIsLoading(true);
        getDyanamicTypeListTableData(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            value
        )
            .then((response) => {
                let FilteredTableData = response.filter(
                    (x) => x.dynamicFieldId === value
                );

                setDynamicTableData(FilteredTableData);

                setIsLoading(false);

            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //dyanamicFieldAddValue
    var formdataDyanamicFieldValue = {
        DynamicFieldValueId: 0,
        DynamicFieldValue: dynamicFieldValue,
        DynamicFieldId: dynamidFieldIdForm, //labelDataInputName
        IsActive: status,
        CreatedBy: userDetails.userId,
        CreatedDate: "",
        ModifiedBy: 0,
        ModifiedDate: "",
    };
    //let id = atobSecure(encryptedId);
    var formdataDyanamicFieldValueEdit = {
        DynamicFieldValueId: dynamicFieldValueIdUpdate,
        DynamicFieldValue: dynamicFieldValueUpdate, //-->dynamicFieldValue
        DynamicFieldId: dynamidFieldIdForm, //labelDataInputName
        IsActive: statusTableUpdate,
        CreatedBy: 0,
        CreatedDate: "",
        ModifiedBy: userDetails.userId,
        ModifiedDate: "",
    };

    function addDynamicFieldsValue() {
        setIsLoading(true);

        dyanamicFieldAddValue(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataDyanamicFieldValue
        )
            .then((response) => {
                setIsLoading(false);

                if (response.status == 1) {
                    handleSnackOpen(response.message, "success");
                   
                    setOpenAddModal(false);


                }
                else if (response.status == 0) {
                    handleSnackOpen(response.message, "error");
                    
                    setOpenAddModal(false);
                }
                else {
                    handleSnackOpen("Error While Submitting Dynamic Field Data", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    //update field-value
    function addDynamicFieldsValueUpdate() {
        setIsLoading(true);
        //dyanamicFieldAddValueUpdate-->dyanamicFieldAddValue
        dyanamicFieldAddValueUpdate
        (
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataDyanamicFieldValueEdit
        )
            .then((response) => {
                setIsLoading(false);

                if (response.status == 2) {
                    handleSnackOpen(response.message, "success");
                   
                    setOpenModal(false);


                }
                else if (response.status == 0) {
                    handleSnackOpen(response.message, "error");
                   
                    setOpenModal(false);
                }
                else {
                    handleSnackOpen("Error While Submitting Dynamic Field Data", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    useEffect(() => {
        getDyanamicTypeMenus();

        getAllTools();
        if (encryptedId) {
            getDyanamicFieldAllData(atobSecure(encryptedId));
        }
    }, []);
    return (
        <div className="main-component">
            <Container className="box-shadow" maxWidth="xl">
                <Grid container spacing={2} className="mb-1">
                    <Grid item xs={12}>
                        <h3 className="m-0">Dynamic Fields</h3>
                    </Grid>
                    <Grid container spacing={2} className="py-1">
                        <Grid item xs={12}>
                            <h4 className="m-0 pb-1">
                                {encryptedId ? "Edit Dynamic Field" : "Add New Dynamic Field"}
                            </h4>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                label="Dynamic Field Name"
                                id="labelid"
                                name="labelName"
                                value={labelDataInput}
                                onChange={handleInputChangeLabel}
                                fullWidth
                                variant="filled"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                label="Dynamic Field Type"
                                id="LabelType"
                                name="LabelType"
                                value={labelType}
                                onChange={handleInputChange}
                                fullWidth
                                select
                                variant="filled">
                                {getDynamidTypeList.map((item) => (
                                    <MenuItem
                                        key={item.dynamicFieldTypeId}
                                        value={item.dynamicFieldTypeId}>
                                        {item.dynamicFieldTypeName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                label="Status"
                                name="statusSubmit"
                                value={statusSubmit}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: status ? true : false }}
                                fullWidth
                                select
                                variant="filled">
                                <MenuItem key={1} value={true}>
                                    Active
                                </MenuItem>
                                <MenuItem key={0} value={false}>
                                    Inactive
                                </MenuItem>
                            </TextField>
                        </Grid>

                        <Grid container spacing={2} className="mt-1 d-flex jc-center pb-1">
                            <Grid item xs={6} md={1}>
                                <Button
                                    fullWidth
                                    disabled={!labelDataInput || !labelType}
                                    onClick={encryptedId?submitDynamicFieldsUpdate:submitDynamicFields}
                                    className="pt-button--primary">
                                    {encryptedId ? "Update" : "Submit"}
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={1}>
                                <Link to={"/FieldsList"}>
                                    <Button fullWidth className="pt-button--secondary">
                                        Back
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider />

                    {encryptedId && fieldTypeUpdate !== 3 ? (
                        <Grid container spacing={1} className="py-1">
                            <Grid item xs={12}>
                                <h4 className="m-0 pb-1">Dynamic Field values</h4>
                            </Grid>

                            <Grid item xs={12} className="req-grid master">
                                <MaterialTable
                                    title=""
                                    columns={columns}
                                    data={dynamicTableData}
                                    icons={tableIcons}
                                    options={{
                                        exportButton: {
                                            csv: true,
                                            pdf: false,
                                        },
                                        search: true,
                                        exportAllData: true,
                                        exportFileName: "Additional Field-Value Details",
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 20, 30, 50],
                                        rowStyle: (x) => {
                                            if (x.tableData.id % 2) {
                                                return { backgroundColor: "#F2FCFC" };
                                            } else {
                                                return { backgroundColor: "white" };
                                            }
                                        },
                                    }}
                                    actions={[
                                        {
                                            icon: AddIcon,
                                            isFreeAction: true,
                                           
                                            onClick: () => {
                                                clickAddDynamicValue();
                                            },
                                        },

                                        {
                                            icon: EditIcon,
                                            tisFreeAction: true,
                                          
                                            onClick: (event, rowData) =>
                                                clickEditHardCodeMaster(rowData),
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Divider />
                </Grid>
            </Container>
            <Dialog
                aria-labelledby="litigation-form--registration"
                open={openModal}
                fullWidth
                maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <h5>Edit Field-Value:-</h5>
                        <Grid container item spacing={2} maxWidth="xl">
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Dynamic Field Name"
                                    id="additionalField"
                                    name="additionalField"
                                    value={additionalField}
                                    //onChange={handleInputChange}
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    variant="filled"></TextField>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Field Value"
                                    id="dynamicFieldValueUpdate"
                                    name="dynamicFieldValueUpdate"
                                    value={dynamicFieldValueUpdate}
                                    onChange={handleInputChangeDynamicFieldValuewhileUpdate}
                                    fullWidth
                                    variant="filled"
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Status"
                                    name="statusTableUpdate"
                                    value={statusTableUpdate}
                                    onChange={handleInputChange}
                                    InputLabelProps={{ shrink: status ? true : false }}
                                    fullWidth
                                    select
                                    variant="filled">
                                    <MenuItem key={1} value={true}>
                                        Active
                                    </MenuItem>
                                    <MenuItem key={0} value={false}>
                                        Inactive
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={6} className="d-flex jc-center width-100">
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--secondary"
                                id="SupplierLoginBtn"
                                onClick={handleCloseModal}>
                                close
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={addDynamicFieldsValueUpdate}>
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-labelledby="litigation-form--registration"
                open={openAddModal}
                fullWidth
                maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <h5>Add New Field Value:-</h5>
                        <Grid container item spacing={2} maxWidth="xl">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Field Value"
                                    id="dynamicFieldValue"
                                    name="dynamicFieldValue"
                                    value={dynamicFieldValue}
                                    onChange={handleInputChangeDynamicFieldValue}
                                    fullWidth
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Status"
                                    name="status"
                                    value={status}
                                    onChange={handleInputChange}
                                    InputLabelProps={{ shrink: status ? true : false }}
                                    fullWidth
                                    select
                                    variant="filled">
                                    <MenuItem key={1} value={true}>
                                        Active
                                    </MenuItem>
                                    <MenuItem key={0} value={false}>
                                        Inactive
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={6} className="d-flex jc-center width-100">
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--secondary"
                                id="SupplierLoginBtn"
                                onClick={handleCloseModalforAdd}>
                                close
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                disabled={!dynamicFieldValue}
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={addDynamicFieldsValue}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoading === true ? <Loader /> : <></>}
        </div>
    );
}
