import { createTheme } from "@mui/material/styles";
import "../assets/colors.css";

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "SiemensSans-Bold",
          borderRadius: 0,
          /*Hero Button*/
          "&.Mui-disabled": {
            cursor: "no-drop",
            pointerEvents: "auto",
          },
          "&.pt-button--hero": {
            background: `var(--btn-hero-bg-gradient)`,
            color: `var(--btn-hero-text-color)`,
            padding: `10px`,
            fontSize: `1rem`,

            "&.pt-button--hero:hover": {
              background: `var(--btn-hero-bg-hover)`,
            },

            "&.pt-button--hero:focus": {
              background: `var(--btn-hero-bg-active)`,
            },
          },
          /*Primary Button*/
          "&.pt-button--primary": {
            background: `var(--btn-primary-bg-color)`,
            color: `var(--btn-primary-text-color)`,
            padding: `10px`,
            border: `1px solid var(--btn-primary-bg-color)`,

            "&.pt-button--primary:hover": {
              background: `var(--btn-primary-bg-hover)`,
            },

            "&.pt-button--primary:focus": {
              background: `var(--btn-primary-bg-active)`,
            },
          },
          "&.pr-button--primary": {
            background: `var(--btn-primary-bg-color)`,
            color: `var(--btn-primary-text-color)`,
            padding: `5px`,
            margin: `0px`,

            "&.pt-button--primary:hover": {
              background: `var(--btn-primary-bg-hover)`,
            },

            "&.pt-button--primary:focus": {
              background: `var(--btn-primary-bg-active)`,
            },
          },
          /*Secondary Button*/
          "&.pt-button--secondary": {
            background: `var(--btn-secondary-bg-color)`,
            color: `var(--btn-secondary-text-color)`,
            border: `1px solid var(--blue-green)`,
            padding: `10px`,
            transition: `0.5s`,

            "&.pt-button--secondary:hover": {
              background: `var(--btn-secondary-bg-color-hover)`,
              color: `var(--btn-secondary-text-color-hover)`,
              border: `1px solid #005159`,
            },

            "&.pt-button--secondary:focus": {
              background: `var(--btn-secondary-bg-color-active)`,
              color: `var(--btn-secondary-text-color-active)`,
            },
          },

          /*Reject Button*/
          "&.pt-button--reject": {
            background: `var(--btn-delete-bg-color)`,
            color: `var(--btn-delete-text-color)`,
            border: `1px solid var(--light-red)`,
            padding: `10px`,
            transition: `0.5s`,

            "&.pt-button--reject:hover": {
              background: `var(--btn-delete-bg-color-hover)`,
              color: `var(--btn-delete-text-color-hover)`,
              border: `1px solid var(--light-red)`,
            },

            "&.pt-button--reject:focus": {
              background: `var(--btn-delete-bg-color-hover)`,
              color: `var(--btn-delete-text-color-hover)`,
            },
          },

          //Button in PO
          "&.pr-button--secondary": {
            background: `var(--btn-secondary-bg-color)`,
            color: `var(--btn-secondary-text-color)`,
            border: `1px solid var(--blue-green)`,
            padding: `7px`,
            margin: `9px`,
            //display: "flex",
            //justifyContent: "center",

            transition: `0.5s`,

            "&.pr-button--secondary:hover": {
              background: `var(--btn-secondary-bg-color-hover)`,
              color: `var(--btn-secondary-text-color-hover)`,
              border: `1px solid #005159`,
            },

            "&.pr-button--secondary:focus": {
              background: `var(--btn-secondary-bg-color-active)`,
              color: `var(--btn-secondary-text-color-active)`,
            },
          },
          /*tertiary Button*/
          "&.pt-button--tertiary": {
            background: `var(--btn-tertiary-bg-color)`,
            color: `var(--btn-tertiary-text-color)`,
            padding: `10px`,

            "&.pt-button--tertiary:hover": {
              background: `var(--btn-tertiary-bg-color-hover)`,
              color: `var(--btn-tertiary-text-color-hover)`,
            },

            "&.pt-button--tertiary:focus": {
              background: `var(--btn-tertiary-bg-color-active)`,
              color: `var(--btn-tertiary-text-color-active)`,
            },
          },
          /*Delete Button*/
          "&.pt-button--delete": {
            background: `var(--btn-delete-bg-color)`,
            color: `var(--btn-delete-text-color)`,
            padding: `5px`,
            transition: `0.25s`,
            minWidth: `auto`,
            "&.pt-button--delete:hover": {
              background: `var(--btn-delete-bg-color-hover)`,
              color: `var(--btn-delete-text-color-hover)`,
            },
          },
          //Button in PO
          "&.pr-button--delete": {
            background: `var(--btn-delete-bg-color)`,
            color: `var(--btn-delete-text-color)`,
            padding: `7px`,
            margin: `9px`,
            // display: "flex",
            // justifyContent: "center",
            transition: `0.25s`,
            //  minWidth: `auto`,
            "&.pr-button--delete:hover": {
              background: `var(--btn-delete-bg-color-hover)`,
              color: `var(--btn-delete-text-color-hover)`,
            },
          },

          /*Quick Links Button*/
          "&.pt-button--quickLinks": {
            backgroundColor: `var(--btn-quick-links-bg-color)`,
            color: `var(--btn-quick-links-text-color)`,
            transition: `0.25s`,
            "&.pt-button--quickLinks:hover": {
              background: `var(--btn-quick-links-bg-color-hover)`,
              color: `var(--btn-quick-links-text-color-hover)`,
            },
          },

          /*Feedback Button*/
          "&.pt-button--feedback": {
            background: `var(--feedback-btn-bg-color)`,
            color: `var(--feedback-btn-text-color)`,
            "&.pt-button--feedback:hover": {
              background: `var(--feedback-btn-bg-color-hover)`,
            },
          },
          "&.pt-button--feedback-submit": {
            width: `25%`,
          },
          "&.pt-button--view-account": {
            width: `75%`,
            fontSize: `14px`,
            padding: `10px`,
          },
          /*Preview Button*/
          "&.pt-button--preview-button": {
            marginLeft: `20px`,
            padding: `5px 10px`,
            fontSize: `12px`,
          },
          "&.pt-button--online-pact-navbtn": {
            borderBottom: `2px solid var(--transparent)`,
            padding: `10px 20px`,
            "&&&&:hover": {
              borderBottom: `2px solid var(--light-green--2)`,
              backgroundColor: "#00CCCC33",
            },
            "&&&&:focus": {
              background: `var(--light-green)`,
            },
          },
          "&.myid-login-btn": {
            padding: "8px 40px",
          },
          "&.supplier-login-btn": {
            padding: "8px 40px",
          },
          "&.bulk-upload-submit-btn": {
            marginTop: "4px",
          },
          "&.pt-button--save": {
            marginTop: "20px",
          },
          "&.generate-qrcode-btn": {
            marginBottom: "20px",
          },
          //multiline field
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: `var(--dark-blue)`,
          fontSize: `14px`,
          "&$focused": {
            color: `var(--dark-blue)`,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: `0px`,
          borderTopRightRadius: `0px`,
          backgroundColor: `var(--input-bg-color)`,
          // background: `var--input-bg-color)`,
          fontSize: "14px",
          "&.Mui-focused": {
            backgroundColor: `var(--input-bg-color)`,
          },

          "&$disabled": {
            color: `var(--disable-text-color)`,
            cursor: "no-drop",
          },
          "&&&&:hover": {
            backgroundColor: `var(--input-bg-color-hover)`,
          },
          "&.Mui-error": {
            //  backgroundColor: `var(--error-red-bg-color)`,
            borderBottom: `1px solid var(--error-red--color)`,
            color: `var(--error-red--color)`,

            //  "&&&&:hover": {
            //      backgroundColor: `var(--error-red-bg-color)`,
            //  },
            "& underline": {
              "&&&&:before": {
                borderBottom: `1px solid var(--error-red--color)`,
              },
              "&&&&:after": {
                borderBottom: `2px solid var(--error-red--color)`,
              },
            },
          },
        },
        underline: {
          "&&&&:before": {
            borderBottom: `1px solid #00B3B3`,
          },
          "&&&&:after": {
            borderBottom: `2px solid #00B3B3`,
          },
        },
        input: {
          borderTopLeftRadius: `0px`,
          borderTopRightRadius: `0px`,
          //  backgroundColor: `var(--input-bg-color)`,
          "&&&&:hover": {
            backgroundColor: `#D1FFF2`,
          },
          "&#supplierEmail": {
            textTransform: "lowercase",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: `var(--blue-green)`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "SiemensSlab-Black",
        },
        h2: {
          fontFamily: "SiemensSlab-Black",
        },
        h3: {
          fontFamily: "SiemensSlab-Black",
        },
        h4: {
          fontFamily: "SiemensSlab-Black",
        },
        h5: {
          fontFamily: "SiemensSlab-Black",
        },
        h6: {
          fontFamily: "SiemensSlab-Black",
          fontSize: `1rem`,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          color: "var(--circular-progress-color)",
        },
      },
    },
  },
});
