import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import "./Administration.scss";

export default function Administration() {
  useEffect(() => {}, []);

  return (
    <div className="home-component amt-master-block">
      <Container maxWidth="xl" className="mt-2">
        <Grid container spacing={3} className="d-flex jc-center">
          <Grid item xs={12} sm={4}>
            <Link to="/userList">
              <div
                className="amt-homepage--card--block-item amt-homepage--card--block-item--userManagement"
                id="amtHomepageSales">
                {/*The image of each card will be added as background*/}
                <span className="amt-homepage--card--block-item-text text-uppercase">
                  User Management
                </span>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link to="/toolsList">
              <div
                className="amt-homepage--card--block-item amt-homepage--card--block-item--master"
                id="amtHomepageServices">
                {/*The image of each card will be added as background*/}
                <span className="amt-homepage--card--block-item-text text-uppercase">
                  Master
                </span>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link to="/FieldsList">
              <div
                className="amt-homepage--card--block-item amt-homepage--card--block-item--master"
                id="amtHomepageServices">
                {/*The image of each card will be added as background*/}
                <span className="amt-homepage--card--block-item-text text-uppercase">
                  Dynamic Fields
                </span>
              </div>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
