import React, { useState, useEffect } from "react";
import { Grid, Container, TextField, MenuItem, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import MaterialTable from "material-table";
import { tableIcons } from "../../../Utility/utility";
import AddIcon from "@mui/icons-material/Add";
import { addToolData, getDyamicControlData, getDyamicAllData, subFieldData, GetAllSubLevelData, addDialogData, editAddTool, GetAllToolListData, GetSubLevelDetailsData, addDialogUpdateData, getApplicationList, getDyamicControlDataNew } from "../../../Redux/API/api_userManagemnt";
import Loader from "../../../Components/Loader/Loader";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";


const data = [
    {
        1: "Tracker Type",
        2: "Super Admin",
        3: "Active",
    },
    {
        1: "MIS BU",
        2: "Admin",
        3: "Inactive",
    },
    {
        1: "Access Type",
        2: "CFT",
        3: "Active",
    },
];

export default function AddTool() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);//azure-token
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [isLoader, setIsLoader] = useState(false);
    const [toolName, setToolName] = useState("");
    const [appValue, setAppValue] = useState(0);
    const [toolId, setToolId] = useState();
    const [status, setStatus] = useState(true);
    const [controls, setControls] = useState([]);
    const [controlId, setControlId] = useState("");
    const [controlValue, setControlValue] = useState("");
    const [controlStatus, setControlStatus] = useState(true);
    const [subFieldDD, setSubFieldDD] = useState([]);
    const [data, setData] = useState([]);
    const [Id, setId] = useState("");
    const [dynamicId, setDynamicId] = useState();
    const [subFieldUpdateValue, setSubFieldUpdateValue] = useState("");
    const [upId, setUpId] = useState(null);
    const [subLevelId, setSubLevelId] = useState();
    const [updateStatus, setUpdateStatus] = useState();
   
    const [inputControls, setInputControls] = useState([]);
    const [applicationList, setApplicationList] = useState([]);
   
    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const handleCloseModalUpdate = () => {
        setOpenModalUpdate(false);
    };
    const columnsForm = [
        { title: "Fields Name", field: "dynamicFieldName" },
        { title: "Field Value", field: "value" },
        { title: "Status", field: "isActive" },
       
    ];
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

   
    const handleClose = (e) => {
        setOpen(false);
        if (alertType === "success" && !encryptedId) {
            window.location.href = "/toolslist";
        }
        if (alertType === "success" && encryptedId) {
            window.location.reload();
            window.location.href = "/toolslist";
        }
    };

    const handleInputChange = (event) => {
        let valid;
        switch (event.target.name) {
            case "toolName":
                setToolName(event.target.value);
                break;
            case "status":
                setStatus(event.target.value);
                break;
            case "subField":
                setControlId(event.target.value);
                break;
            case "controlValue":
                setControlValue(event.target.value);
                break;
            case "controlStatus":
                setControlStatus(event.target.value);
                break;
            case "updateStatus":
                setUpdateStatus(event.target.value);
                break;
            case "applicationname":
                setAppValue(event.target.value);
                break;
            default:
                break;
        }
    };
    const [subUpdatevalue, setSubUpdatevalue] = useState("");
    const handleInputChangeUpdateValue = (e) => {
        setSubUpdatevalue(e.target.value);
    }


   
    //getDyamicControlDataNew
    const [value, setValue] = useState();
    const [lable, setLable] = useState();
    function getDyanamicControlFields() {
        getDyamicControlDataNew(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId)
            .then((response) => {
               
                setInputControls(response);
               

            })
            .catch((error) => {
                console.log("error", error);
            });
    }


    //setInputControls set to this while getting data from api
    const [selectedOptionsInputControls, setSelectedOptionsInputControls] =
        useState([]);
    const [inputControlsData, setInputControlsData] = useState([]);
    const [filterInputControls, setFilterInputControls] = useState("");
    function editInputControlsByUser(filterInputControls) {
        var arrayInputControls = filterInputControls.split(",");
        setSelectedOptionsInputControls(
            inputControls.filter((x) => arrayInputControls.includes(x.value))
        );
    }
    let inputControlsArr = [];
    const [inputControlsString, setInputControlsString] = useState("");
    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }
    function onChangeInputControls(value, event) {
      let inputControlsValue = [];
      if (event.action === "select-option" && event.option.value === "*") {
        this.setState(this.options);
      } else if (
        event.action === "deselect-option" &&
        event.option.value === "*"
      ) {
        this.setState([]);
        inputControlsValue = [];
      } else if (event.action === "deselect-option") {
        this.setState(value.filter((o) => o.value !== "*"));
      } else if (value.length === this.options.length - 1) {
        this.setState(this.options);
      } else {
        this.setState(value);
      }
      if (event.action === "select-option" && event.option.value === "*") {
        inputControlsValue = inputControls;
      } else if (
        event.action === "deselect-option" &&
        event.option.value === "*"
      ) {
        inputControlsValue = [];
      } else {
        inputControlsValue = value;
      }
      for (let i = 0; i < inputControlsValue.length; i++) {
        if (inputControlsValue[i].value !== undefined)
          inputControlsArr.push(inputControlsValue[i].value);
      }
      setInputControlsString(inputControlsArr.toString());
     
    }
    

    function handleSubmit() {
      let formData = {
        ToolName: toolName,
        IsActive: status,
        DynamicfieldIds: inputControlsString,
        ApplicationId: appValue,
      };

      setIsLoader(true);
      addToolData(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
        formData
      )
        .then((response) => {
          setIsLoader(false);
          if (response.status === 1) {
            handleSnackOpen(response.message, "success");
            setToolId(response.toolId);
          } else if (response.status === 2) {
            handleSnackOpen(response.message, "error");
          } else {
            handleSnackOpen("Error while Adding Tool Details.", "error");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function atobSecure(value) {
      const { atob } = require("abab");
      let arrValue = atob(atob(value)).split(" ");
      let valueActual = arrValue[0];
      return valueActual;
    }

    function getURLParameter(sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split("&");
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] == sParam) {
          return decodeURIComponent(sParameterName[1]);
        }
      }
      // If parameter is not found, return null or empty string
      return null; // or return '';
    }

    var encryptedId = getURLParameter("id");

    //GetAllToolListData

    function getAllToolData(value) {
      setIsLoader(true);
      getDyamicAllData(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
        value
      )
        .then((response) => {
          setToolName(response[0].toolName);
          setAppValue(response[0].applicationId);

          setInputControlsString(response[0].dynamicFieldId);
          setFilterInputControls(response[0].dynamicFieldId);
            setStatus(response[0].isActive == "Active" ? true : false);
          setId(response[0].toolId);
          setDynamicId(response[0].dynamicFieldId);
          getSubAllData(response[0].toolId);
          subFieldValues(response[0].dynamicFieldId);
          setIsLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    //GetAllSubLevelData

    function getSubAllData(value) {
      setIsLoader(true);
      GetAllSubLevelData(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
        value
      )
        .then((response) => {
          setData(response);
          setIsLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    //getApplicationList

    function getApplicationListData() {
      setIsLoader(true);
      getApplicationList(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId
      )
        .then((response) => {
          setApplicationList(response);
          setIsLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    //subFieldData

    function subFieldValues(dynamicId) {
      setIsLoader(true);
      subFieldData(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
        dynamicId
      )
        .then((response) => {
          setSubFieldDD(response);
          setIsLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    const resetDialogFields = () => {
      setControlId("");
      setControlValue("");
    };
    //handleSubmitDialog-->addDialogData
    function handleSubmitDialog() {
      let formData = {
        ToolId: Id,
        SublevelId: controlId,
        Value: controlValue,
        IsActive: controlStatus,
      };

      setIsLoader(true);

      addDialogData(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
        formData
      )
        .then((response) => {
          setIsLoader(false);
          if (response.status === 1) {
            handleSnackOpen(response.message, "success");
            getSubAllData(response.toolId);
            resetDialogFields();

            setOpenModal(false);
          } else {
            handleSnackOpen("Error while Adding Tool Details.", "error");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    //edit sub-field

    function clickEditHardCodeMaster(rowData) {
      setSubFieldUpdateValue(rowData.dynamicFieldName);
      setUpdateStatus(rowData.isActive);
      setSubUpdatevalue(rowData.value);
      setUpId(rowData.id);
      setSubLevelId(rowData.sublevelId);
      setOpenModalUpdate(true);
    }
    function handleUpdateDialog() {
      let formData = {
        SublevelId: subLevelId,
        Id: upId,
        Value: subUpdatevalue,
        IsActive: updateStatus,
        ModifiedBy: userDetails.userId,
      };
      setIsLoader(true);

      addDialogUpdateData(
        siteToken,
        azureToken.token,
        UID.id,
        userDetails.userEmail,
        userDetails.gid,
        userDetails.roleId,
        formData
      )
        .then((response) => {
          setIsLoader(false);
          if (response.status === 1) {
            handleSnackOpen(response.message, "success");
            setOpenModalUpdate(false);
            window.location.reload();
            setOpenModal(false);
          } else {
            handleSnackOpen("Error while Adding Tool Details.", "error");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }


    //editAddTool

    let formdataEdit = {
        toolId: Id,
        ToolName: toolName,
        IsActive: status,
        DynamicfieldIds: inputControlsString,
        ApplicationId: appValue,
    }
    function handleEditAddTool() {
        setIsLoader(true);
        editAddTool(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleId,
            formdataEdit)
            .then((response) => {
                

                setIsLoader(false);
                if (response.result.status === 1) {
                    handleSnackOpen(response.result.message, "success");
                   
                } else {
                    handleSnackOpen("Error while Updating Tools Details", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    useEffect(() => {
        if (encryptedId) {
            getAllToolData(atobSecure(encryptedId));


        }
        getApplicationListData();
        getDyanamicControlFields();
    }, [encryptedId]);

    useEffect(() => {
        editInputControlsByUser(filterInputControls);
    }, [filterInputControls, inputControlsData]);

    return (
        <div className="main-component">
            <Container maxWidth="xl" className="box-shadow">
                <Grid container spacing={3} className="pb-1">
                    <Grid item xs={12}>
                        <h4 className="m-0">{encryptedId ? "Update Tool" : "Add New Tool"}  :-</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Tool Name"
                            id="toolName"
                            name="toolName"
                            value={toolName}
                            onChange={handleInputChange}
                            fullWidth
                            variant="filled"
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Application Name"
                            id="applicationname"
                            name="applicationname"
                            value={appValue}
                            onChange={handleInputChange}
                            fullWidth
                            select
                            variant="filled">
                           
                            {applicationList.map((item) => (
                                <MenuItem key={item.applicationId} value={item.applicationId}>
                                    {item.applicationName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="tool-multi">
                        <ReactMultiSelectCheckboxes
                            options={[{ label: "All", value: "*" }, ...inputControls]}
                            placeholderButtonLabel="Dynamic Controls"
                            getDropdownButtonLabel={getDropdownButtonLabel}
                            value={selectedOptionsInputControls}
                            onChange={onChangeInputControls}
                            setState={setSelectedOptionsInputControls}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Status"
                            name="status"
                            value={status}
                            onChange={handleInputChange}
                            fullWidth
                            select
                            variant="filled">
                            <MenuItem key={1} value={true}>
                                Active
                            </MenuItem>
                            <MenuItem key={0} value={false}>
                                Inactive
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-1 d-flex jc-center pb-1">
                    <Grid item xs={6} md={1}>
                        <Button
                            disabled={( !toolName || !appValue )}
                            onClick={encryptedId ? handleEditAddTool : handleSubmit}
                            fullWidth
                            className="pt-button--primary">
                            {encryptedId ? "Update" : "Submit"}
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Link to={"/toolsList"}>
                            <Button fullWidth className="pt-button--secondary">
                                Back
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
              
              
            </Container>
            <Dialog
                aria-labelledby="litigation-form--registration"
                open={openModal}
                fullWidth
                maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <h5>Tool Specific Details:-</h5>
                        <Grid container item spacing={2} maxWidth="xl">
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Sub-Field"
                                    id="Sub_Field"
                                    name="subField"
                                    value={controlId}
                                    onChange={handleInputChange}
                                    fullWidth
                                    select
                                    variant="filled">
                                    {subFieldDD.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Value"
                                    id="value"
                                    name="controlValue"
                                    value={controlValue}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="filled"></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                    label="Status"
                                    name="controlStatus"
                                    value={controlStatus}
                                    onChange={handleInputChange}
                                    fullWidth
                                    select
                                    variant="filled">
                                    <MenuItem key={1} value={true}>
                                        Active
                                    </MenuItem>
                                    <MenuItem key={0} value={false}>
                                        Inactive
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={6} className="d-flex jc-center width-100">
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--secondary"
                                id="SupplierLoginBtn"
                                onClick={handleCloseModal}>
                                close
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={handleSubmitDialog}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-labelledby="litigation-form--registration"
                open={openModalUpdate}
                fullWidth
                maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <h5>Update Tool Specific Details:-</h5>
                        <Grid container item spacing={2} maxWidth="xl">
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Sub-field"
                                    id="subfieldUpdate"
                                    name="subfieldUpdate"
                                    value={subFieldUpdateValue}
                                    //onChange={handleInputChangeUpd}
                                    fullWidth
                                    variant="filled"></TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Value"
                                    id="subUpdatevalue"
                                    name="subUpdatevalue"
                                    value={subUpdatevalue}
                                    onChange={handleInputChangeUpdateValue}
                                    fullWidth
                                    variant="filled"></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                    label="Status"
                                    name="updateStatus"
                                    value={updateStatus}
                                    onChange={handleInputChange}
                                    fullWidth
                                    select
                                    variant="filled">
                                    <MenuItem key={1} value={true}>
                                        Active
                                    </MenuItem>
                                    <MenuItem key={0} value={false}>
                                        Inactive
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={6} className="d-flex jc-center width-100">
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--secondary"
                                id="SupplierLoginBtn"
                                onClick={handleCloseModalUpdate}>
                                close
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={2} lg={1} className="pl-0">
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={handleUpdateDialog}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoader ? <Loader /> : <></>}
        </div>
    );
}
