import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { LoginRoute } from "./Routes/LoginRoute";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Header from "./Components/Header/Header";
import {
  MasterRoute,
  OtherRolesRoute,
  RPARolesRoute,
} from "./Routes/MasterRoute";
import {
  saveAzureTokenAction,
  storeUserDetailsAction,
} from "./Redux/Actions/actions";
import Loader from "./Components/Loader/Loader";
import { authenticateUserBasedOnValidToken } from "./Redux/API/api_login";

function App() {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const [status, setStatus] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  function authenticateUserWithGID() {
    setIsLoader(true);
    authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken)
      .then((response) => {
        if (response) {
          dispatch(storeUserDetailsAction(response)); //userDetails
          setIsLoader(false);
        } else {
          setIsLoader(false);
          console.log("error");
        }
      })
      .catch((error) => {});
  }
  useEffect(() => {
    if (activeAccount) {
      dispatch(saveAzureTokenAction(activeAccount.idToken));
      authenticateUserWithGID();
    } else {
      setIsLoader(false);
    }
  }, [accounts]);
  return (
    <BrowserRouter>
      <div className="App">
        {userDetails && userDetails.roleId > 0 ? (
          <div className="">
            <Header>Header Component</Header>
            {userDetails.roleId === 9
              ? MasterRoute
              : userDetails.roleId > 0 && userDetails.roleId === 12
              ? RPARolesRoute
              : userDetails.roleId > 0 &&
                (userDetails.roleId !== 12 || userDetails.roleId !== 9)
              ? OtherRolesRoute
              : LoginRoute}
          </div>
        ) : (
          LoginRoute
        )}
        {isLoader === true ? <Loader /> : <></>}
      </div>
    </BrowserRouter>
  );
}

export default App;
