// JavaScript source code
import { baseApiURL } from "../../Utility/utility";

export async function addUserData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(
            baseApiURL + "UserManagement/SaveUserDetails",
            options
        );
        const data = await response.json();
        return data.response;
    } catch (error) {
        console.log("Error : ", error);
    }
}

export async function GetAllUser(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    userIntId
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };

    try {
       
        const response = await fetch(
            baseApiURL + "UserManagement/GetMstUser?UserId=" + userIntId,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}

//! Get GID based Data
export async function GetGIDBasedData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(
            baseApiURL +
            "accessrequest/GetUserDetailsFromSCDApi?AddingUserGID=" +
            gid,
            options
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error : ", error);
    }
}

//GetMstDivision

export async function getDivisionMenus(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId) {
    const options = {
        method: "get",
        headers: {
            
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(baseApiURL + "Master/GetMstDivision"
            , options);
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}

export async function getDepartmentMenus(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
) {
    const options = {
        method: "get",

        headers: {
            
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId, 
        },
        
    };

    try {
        const response = await fetch(
            baseApiURL + "Master/GetMstDepartment"
            ,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//GetMstRoleList
export async function getRoleMenus(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
  
    roleId) {
    const options = {
        method: "get",
        headers: {
            
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(baseApiURL + "Master/GetRoleList"
            , options);
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//UpdateUserDetails
export async function editUserMgnt(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
     
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "UserManagement/UpdateUserDetails",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//GetAccessRequestData
export async function getRpaData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId
) {
  const options = {
    method: "get",
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "AccessRequest/GetAccessRequestData",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getRequestsList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId
) {
  const options = {
    method: "get",
    headers: {
     
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "accessrequest/GetAccessRequestDataForRole",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export function approveRPA(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
    RId,
    formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
      },
      body: JSON.stringify(formData),
  };
  return fetch(
    baseApiURL +
      "AccessRequest/ApprovedByL2Approver",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.response;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function rejectRPA(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  id,
    reason,
  formData,
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
      },
      body: JSON.stringify(formData),
  };
  return fetch(
    baseApiURL +
      "AccessRequest/RejectedByL2Approver",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.response;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//approve by l1
export function approveL1(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
    RId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
      },
      body: JSON.stringify(formData),
  };
  return fetch(
    baseApiURL +
      "AccessRequest/ApprovedByL1" ,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//rejectedby l1
export function rejectL1(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  id,
    reason,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
      },
      body: JSON.stringify(formData),
  };
  return fetch(
    baseApiURL +
      "AccessRequest/RejectedByL1",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//toolist
export async function getToolList(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,

  roleId
) {
  const options = {
    method: "get",
    headers: {
     
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstMultiSelectToolList",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//addToolPage
//submit-post api
export async function addToolData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
   
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/AddToolDetail", 
      options
    );
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//getting dynamic value
export async function getDyamicControlData() {
  const options = {
    method: "get",
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetMstDynamicField",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//GetDynamicFieldList
export async function getDyamicControlDataNew(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId
) {
  const options = {
    method: "get",
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetDynamicFieldListForMultiselect", 
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//GetAllDynamicfielddetails
export async function getDyamicAllData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  toolid
) {
  const options = {
    method: "get",
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetAllDynamicfielddetails?toolid=" + toolid,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//GetSubLevelDroupdown

export async function subFieldData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  DynamicIds
) {
  const options = {
    method: "get",
    headers: {
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };

  try {
    const response = await fetch(
      baseApiURL + "Master/GetSubLevelDroupdown?DynamicIds=" + DynamicIds,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//GetAllSubLevelDetails
export async function GetAllSubLevelData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  ToolId
) {
  const options = {
    method: "get",
    headers: {
     
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/GetAllSubLevelDetails?ToolId=" + ToolId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//PostSubLevelDetails
export async function addDialogData(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/PostSubLevelDetails", 
      options
    );
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//GetSubLevelDetailsById

export async function editAddTool(
  siteToken,
  azureToken,
  userId,
  userEmail,
  gid,
  roleId,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
      userId: userId,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "Master/UpdateToolDetail",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
///GetMstToolList
export async function GetAllToolListData() {
    const options = {
        method: "get",
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/GetMstToolList",
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//GetSubLevelDetailsById
export async function GetSubLevelDetailsData() {
    const options = {
        method: "get",
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/GetSubLevelDetailsById",
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//workflow history
export async function getWorkFlowHistoryData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    RequestId) {
    const options = {
        method: "get",
        headers: {
           
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(
            baseApiURL + "AccessRequest/GetRequestHistory?RequestId=" + RequestId,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//update-UpdateSubLevelDetails
export async function addDialogUpdateData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
           
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/UpdateSubLevelDetails",//need to change
            options
        );
        const data = await response.json();
        return data.response;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//GetDynamicFieldTypeList
export async function getDyanamicTypeList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId) {
    const options = {
        method: "get",
        headers: {
           
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/GetDynamicFieldTypeList"
            ,
            options
        );
        const data = await response.json();
        return data.response;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//AddUpdateNewDynamicFieldType
export async function dyanamicFieldSubmit(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/AddUpdateNewDynamicField",
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//update-->dyanamicFieldUpdate
export async function dyanamicFieldUpdate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/UpdateDynamicField",
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//GetDynamicFieldList
export async function getDyanamicFieldListTableData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId) {
    const options = {
        method: "get",
        headers: {
           
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/GetDynamicFieldList",
            options
        );
        const data = await response.json();
        return data.response;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getDyanamicTypeListTableData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId) {
    const options = {
        method: "get",
        headers: {
           
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/GetDynamicFieldValuesList",
            options
        );
        const data = await response.json();
        return data.response;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//AddUpdateDynamicFieldValue
export async function dyanamicFieldAddValue(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/AddUpdateDynamicFieldValue",
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//update dynamic field value
export async function dyanamicFieldAddValueUpdate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
        body: JSON.stringify(formData),
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/UpdateDynamicFieldValue",
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//GetApplicationList
export async function getApplicationList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(
            baseApiURL + "Master/GetApplicationList",
            options
        );
        const data = await response.json();
        return data.response;
    } catch (error) {
        console.log("Error : ", error);
    }
}
//complete details page api call
export async function detailsGetData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleId,
    ToolId,
    RequestId
) {
    const options = {
        method: "get",
        headers: {

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
        },
    };
    try {
        const response = await fetch(
            baseApiURL +
            "accessrequest/GetDynamicValuesForSubmittedRequest?ToolId=" +
            ToolId +
            "&RequestId=" +
            RequestId,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}


