import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Screens/Home/Home";
import Administration from "../Screens/Administration/Administration";
import ToolList from "../Screens/Administration/Master/ToolsList";
import AddTool from "../Screens/Administration/Master/AddTool";
import UserList from "../Screens/Administration/UserManagement/UserList";
import AddUser from "../Screens/Administration/UserManagement/AddUser";
import RPAWorkFlow from "../Screens/WorkFlow/RPAworkflow";
import CompleteDetails from "../Screens/WorkFlow/CompleteDetails";
import WorkflowHistory from "../Screens/WorkFlow/WorkflowHistory";
import AddDynamicFields from "../Screens/Administration/FieldMaster/Adddynamicfields";
import FieldsList from "../Screens/Administration/FieldMaster/FieldsList";
import RequestForm from "../Screens/RegistrationForm/CreateRequestForm";
import Error from "../Screens/Error/Error404";

export const MasterRoute = (
  <Routes>
    <Route path="/signin-oidc*" element={<Navigate to="/" />} />
    <Route path="/signin-oidc#code=*" element={<Navigate to="/" />} />
    <Route path="/" element={<Home />} />
    <Route path="/administration" element={<Administration />} />
    <Route path="/toolsList" element={<ToolList />} />
    <Route path="/addTool" element={<AddTool />} />
    <Route path="/userList" element={<UserList />} />
    <Route path="/addUser" element={<AddUser />} />
    <Route path="/RPAWorkFlow" element={<RPAWorkFlow />} />
    <Route path="/CompleteDetails" element={<CompleteDetails />} />
    <Route path="/WorkflowHistory" element={<WorkflowHistory />} />
    <Route path="/AddDynamicFields" element={<AddDynamicFields />} />
    <Route path="/FieldsList" element={<FieldsList />} />
    <Route path="/createRequest" element={<RequestForm />} />
    <Route path="*" element={<Error />} />
  </Routes>
);

export const OtherRolesRoute = (
  <Routes>
    <Route path="/signin-oidc*" element={<Navigate to="/" />} />
    <Route path="/signin-oidc#code=*" element={<Navigate to="/" />} />
    <Route path="/" element={<Home />} />
    <Route path="/createRequest" element={<RequestForm />} />
    <Route path="/RPAWorkFlow" element={<RPAWorkFlow />} />
    <Route path="/CompleteDetails" element={<CompleteDetails />} />
    <Route path="/WorkflowHistory" element={<WorkflowHistory />} />
    <Route path="*" element={<Error />} />
  </Routes>
);

export const RPARolesRoute = (
  <Routes>
    <Route path="/signin-oidc*" element={<Navigate to="/" />} />
    <Route path="/signin-oidc#code=*" element={<Navigate to="/" />} />
    <Route path="/" element={<Home />} />
    <Route path="/RPAWorkFlow" element={<RPAWorkFlow />} />
    <Route path="/CompleteDetails" element={<CompleteDetails />} />
    <Route path="/WorkflowHistory" element={<WorkflowHistory />} />
    <Route path="*" element={<Error />} />
  </Routes>
);
